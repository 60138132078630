/* Autogenerated (see README) except for manual additions at end */

/* Text */
:root {
  --text-base-1: theme("colors.gray.950");
  --text-base-2: theme("colors.gray.700");
  --text-base-3: theme("colors.gray.500");
  --text-base-4: theme("colors.gray.400");
  --text-base-5: theme("colors.gray.0");
  --text-code-blue: theme("colors.blue.800");
  --text-code-fuchsia: theme("colors.fuchsia.800");
  --text-code-green: theme("colors.green.800");
  --text-code-red: theme("colors.red.800");
  --text-danger-1: theme("colors.red.950");
  --text-danger-2: theme("colors.red.600");
  --text-inverted-1: theme("colors.gray.0");
  --text-inverted-2: theme("colors.gray.200");
  --text-pending: theme("colors.yellow.950");
  --text-premium: theme("colors.violet.950");
  --text-prompt-input: theme("colors.sky.950");
  --text-secondary-1: theme("colors.blue.950");
  --text-secondary-2: theme("colors.blue.700");
  --text-secondary-3: theme("colors.blue.500");
  --text-success-1: theme("colors.green.950");
  --text-success-2: theme("colors.green.600");
  --text-warning-1: theme("colors.orange.950");
  --text-warning-2: theme("colors.orange.600");
}

.dark {
  --text-base-1: theme("colors.neutral.0");
  --text-base-2: theme("colors.neutral.50");
  --text-base-3: theme("colors.neutral.200");
  --text-base-4: theme("colors.neutral.400");
  --text-base-5: theme("colors.neutral.0");
  --text-code-blue: theme("colors.blue.300");
  --text-code-fuchsia: theme("colors.fuchsia.300");
  --text-code-green: theme("colors.green.300");
  --text-code-red: theme("colors.red.300");
  --text-danger-1: theme("colors.neutral.0");
  --text-danger-2: theme("colors.red.500");
  --text-inverted-1: theme("colors.neutral.950");
  --text-inverted-2: theme("colors.neutral.500");
  --text-pending: theme("colors.yellow.300");
  --text-premium: theme("colors.violet.300");
  --text-prompt-input: theme("colors.sky.300");
  --text-secondary-1: theme("colors.neutral.0");
  --text-secondary-2: theme("colors.neutral.100");
  --text-secondary-3: theme("colors.neutral.0");
  --text-success-1: theme("colors.neutral.0");
  --text-success-2: theme("colors.green.500");
  --text-warning-1: theme("colors.neutral.0");
  --text-warning-2: theme("colors.orange.500");
}

/* Icon */
:root {
  --icon-base-1: theme("colors.gray.950");
  --icon-base-2: theme("colors.gray.600");
  --icon-base-3: theme("colors.gray.400");
  --icon-base-4: theme("colors.gray.300");
  --icon-base-5: theme("colors.gray.0");
  --icon-danger-1: theme("colors.red.950");
  --icon-danger-2: theme("colors.red.500");
  --icon-inverted-1: theme("colors.gray.0");
  --icon-pending: theme("colors.yellow.950");
  --icon-premium: theme("colors.violet.950");
  --icon-prompt-input: theme("colors.sky.950");
  --icon-secondary-1: theme("colors.blue.950");
  --icon-secondary-2: theme("colors.blue.700");
  --icon-secondary-3: theme("colors.blue.500");
  --icon-success-1: theme("colors.green.950");
  --icon-success-2: theme("colors.green.500");
  --icon-warning-1: theme("colors.orange.950");
  --icon-warning-2: theme("colors.orange.500");
}

.dark {
  --icon-base-1: theme("colors.neutral.0");
  --icon-base-2: theme("colors.neutral.100");
  --icon-base-3: theme("colors.neutral.200");
  --icon-base-4: theme("colors.neutral.500");
  --icon-base-5: theme("colors.neutral.0");
  --icon-danger-1: theme("colors.neutral.0");
  --icon-danger-2: theme("colors.red.400");
  --icon-inverted-1: theme("colors.neutral.950");
  --icon-pending: theme("colors.neutral.0");
  --icon-premium: theme("colors.neutral.0");
  --icon-prompt-input: theme("colors.neutral.0");
  --icon-secondary-1: theme("colors.neutral.0");
  --icon-secondary-2: theme("colors.neutral.50");
  --icon-secondary-3: theme("colors.neutral.100");
  --icon-success-1: theme("colors.neutral.0");
  --icon-success-2: theme("colors.green.400");
  --icon-warning-1: theme("colors.neutral.0");
  --icon-warning-2: theme("colors.orange.400");
}

/* Background */
:root {
  --background-base-1: theme("colors.gray.0");
  --background-base-2: theme("colors.gray.50");
  --background-base-25: theme("colors.gray.75");
  --background-base-3: theme("colors.gray.100");
  --background-base-325: theme("colors.gray.125");
  --background-base-35: theme("colors.gray.150");
  --background-base-4: theme("colors.gray.300");
  --background-danger-1: theme("colors.red.100");
  --background-danger-2: theme("colors.red.600");
  --background-danger-3: theme("colors.red.700");
  --background-inverted-1: theme("colors.gray.700");
  --background-pending: theme("colors.yellow.100");
  --background-premium: theme("colors.violet.100");
  --background-primary-1: theme("colors.gray.900");
  --background-primary-2: theme("colors.gray.950");
  --background-prompt-input: theme("colors.sky.100");
  --background-prompt-tool: theme("colors.violet.100");
  --background-scrim: theme("colors.gray.200");
  --background-secondary-1: theme("colors.blue.50");
  --background-secondary-2: theme("colors.blue.100");
  --background-secondary-3: theme("colors.blue.700");
  --background-secondary-4: theme("colors.blue.800");
  --background-success-1: theme("colors.green.100");
  --background-warning-1: theme("colors.orange.100");
}

.dark {
  --background-base-1: theme("colors.neutral.950");
  --background-base-2: theme("colors.neutral.900");
  --background-base-3: theme("colors.neutral.700");
  --background-base-4: theme("colors.neutral.400");
  --background-danger-1: theme("colors.red.700");
  --background-danger-2: theme("colors.red.500");
  --background-danger-3: theme("colors.red.600");
  --background-inverted-1: theme("colors.neutral.50");
  --background-pending: theme("colors.yellow.700");
  --background-premium: theme("colors.violet.700");
  --background-primary-1: theme("colors.neutral.50");
  --background-primary-2: theme("colors.neutral.0");
  --background-prompt-input: theme("colors.sky.700");
  --background-prompt-tool: theme("colors.violet.700");
  --background-scrim: theme("colors.neutral.800");
  --background-secondary-1: theme("colors.neutral.800");
  --background-secondary-2: theme("colors.neutral.700");
  --background-secondary-3: theme("colors.blue.600");
  --background-secondary-4: theme("colors.blue.700");
  --background-success-1: theme("colors.green.700");
  --background-warning-1: theme("colors.orange.700");
}

/* Stroke */
:root {
  --stroke-base-1: theme("colors.gray.0");
  --stroke-base-2: theme("colors.gray.100");
  --stroke-base-25: theme("colors.gray.125");
  --stroke-base-3: theme("colors.gray.150");
  --stroke-base-4: theme("colors.gray.200");
  --stroke-base-5: theme("colors.gray.300");
  --stroke-danger-1: theme("colors.red.400");
  --stroke-danger-2: theme("colors.red.600");
  --stroke-danger-3: theme("colors.red.700");
  --stroke-inverted-1: theme("colors.gray.700");
  --stroke-primary-1: theme("colors.gray.900");
  --stroke-primary-2: theme("colors.gray.950");
  --stroke-secondary-1: theme("colors.blue.50");
  --stroke-secondary-2: theme("colors.blue.100");
  --stroke-secondary-3: theme("colors.blue.400");
  --stroke-secondary-4: theme("colors.blue.700");
  --stroke-secondary-6: theme("colors.blue.800");
}

.dark {
  --stroke-base-1: theme("colors.neutral.950");
  --stroke-base-2: theme("colors.neutral.700");
  --stroke-base-25: theme("colors.neutral.700");
  --stroke-base-3: theme("colors.neutral.600");
  --stroke-base-4: theme("colors.neutral.600");
  --stroke-base-5: theme("colors.neutral.400");
  --stroke-danger-1: theme("colors.red.600");
  --stroke-danger-2: theme("colors.red.800");
  --stroke-danger-3: theme("colors.red.600");
  --stroke-inverted-1: theme("colors.neutral.150");
  --stroke-primary-1: theme("colors.neutral.50");
  --stroke-primary-2: theme("colors.neutral.0");
  --stroke-secondary-1: theme("colors.neutral.800");
  --stroke-secondary-2: theme("colors.neutral.700");
  --stroke-secondary-3: theme("colors.neutral.600");
  --stroke-secondary-4: theme("colors.blue.600");
  --stroke-secondary-6: theme("colors.blue.700");
}

/* Manually added */

/* Shadows */
:root {
  --focus-ring: 0px 0px 0px 3px rgba(25, 25, 229, 0.2);
}

.dark {
  --focus-ring: 0px 0px 0px 3px rgba(25, 25, 229, 0.2);
}
