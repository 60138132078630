// Ant design CSS modules.
// All the modules have been broken out so we can
// 1. Not add global styles that mess up things like
// text, colors and links.
// 2. Not import modules we're not using (though this
// might not be an issue as the we use purgeCC

//> from @import "~antd/lib/style/index.less";
@import "~antd/lib/style/themes/index";

//>> from  @import "~antd/lib/style/core/index";
@import "~antd/lib/style/mixins/index";
@import "~antd/lib/style/core/base";
// @import '~antd/lib/style/core/global';
@import "~antd/lib/style/core/iconfont";
@import "~antd/lib/style/core/motion";

// > from @import "~antd/lib/style/components.less";
// @import "~antd/lib/affix/style/index.less";
// @import "~antd/lib/alert/style/index.less";
// @import "~antd/lib/anchor/style/index.less";
// @import "~antd/lib/auto-complete/style/index.less";
// @import "~antd/lib/avatar/style/index.less";
// @import "~antd/lib/back-top/style/index.less";
// @import "~antd/lib/badge/style/index.less";
// @import "~antd/lib/breadcrumb/style/index.less";
@import "~antd/lib/button/style/index.less";
// @import "~antd/lib/calendar/style/index.less";
// @import "~antd/lib/card/style/index.less";
// @import "~antd/lib/carousel/style/index.less";
// @import "~antd/lib/cascader/style/index.less";
@import "~antd/lib/checkbox/style/index.less";
// @import "~antd/lib/collapse/style/index.less";
// @import "~antd/lib/comment/style/index.less";
// @import "~antd/lib/config-provider/style/index.less";
@import "~antd/lib/date-picker/style/index.less";
// @import "~antd/lib/descriptions/style/index.less";
// @import "~antd/lib/divider/style/index.less";
@import "~antd/lib/drawer/style/index.less";
@import "~antd/lib/dropdown/style/index.less";
@import "~antd/lib/empty/style/index.less";
// @import "~antd/lib/form/style/index.less";
// @import "~antd/lib/grid/style/index.less";
// @import "~antd/lib/icon/style/index.less";
// @import "~antd/lib/input/style/index.less";
@import "~antd/lib/input-number/style/index.less";
// @import "~antd/lib/layout/style/index.less";
// @import "~antd/lib/list/style/index.less";
// @import "~antd/lib/locale-provider/style/index.less";
// @import "~antd/lib/mentions/style/index.less";
// @import "~antd/lib/menu/style/index.less";
@import "~antd/lib/message/style/index.less";
@import "~antd/lib/modal/style/index.less";
@import "~antd/lib/notification/style/index.less";
// @import "~antd/lib/page-header/style/index.less";
@import "~antd/lib/pagination/style/index.less";
@import "~antd/lib/popover/style/index.less";
// @import "~antd/lib/progress/style/index.less";
@import "~antd/lib/radio/style/index.less";
// @import "~antd/lib/rate/style/index.less";
@import "~antd/lib/result/style/index.less";
@import "~antd/lib/select/style/index.less";
@import "~antd/lib/skeleton/style/index.less";
@import "~antd/lib/slider/style/index.less";
@import "~antd/lib/space/style/index.less";
@import "~antd/lib/spin/style/index.less";
// @import "~antd/lib/statistic/style/index.less";
// @import "~antd/lib/steps/style/index.less";
@import "~antd/lib/switch/style/index.less";
@import "~antd/lib/table/style/index.less";
// @import "~antd/lib/tabs/style/index.less";
@import "~antd/lib/tag/style/index.less";
@import "~antd/lib/time-picker/style/index.less";
// @import "~antd/lib/timeline/style/index.less";
@import "~antd/lib/tooltip/style/index.less";
@import "~antd/lib/transfer/style/index.less";
@import "~antd/lib/tree/style/index.less";
@import "~antd/lib/tree-select/style/index.less";
@import "~antd/lib/typography/style/index.less";
// @import "~antd/lib/upload/style/index.less";

@primary-color: #1919ef; // primary color for all components
// @link-color: #1890ff; // link color
// @success-color: #52c41a; // success state color
// @warning-color: #faad14; // warning state color
// @error-color: #f5222d; // error state color
@font-size-base: 14px; // major text font size
// @heading-color: rgba(0, 0, 0, 0.85); // heading text color
// @text-color: rgba(0, 0, 0, 0.65); // major text color
// @text-color-secondary: rgba(0, 0, 0, 0.45); // secondary text color
// @disabled-color: rgba(0, 0, 0, 0.25); // disable state color
@border-radius-base: 4px; // major border radius
// @border-color-base: #d9d9d9; // major border color
// @box-shadow-base: 0 2px 8px rgba(0, 0, 0, 0.15); // major shadow for layers

body {
  //   background-color: @primary-color !important;
}

.selector {
  color: @blue-5;
  background-color: @gold-2;
}
